<template>
  <div id="account">
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <div v-if="isAdmin">
      Neuen Produktkatalog hochladen:
      <form id="catalog_upload" method="POST" action="/api/katalog">
        <label for="catalog">Katalogdatei:</label>
        <input type="file" name="catalog" />
        <input type="button" name="submit" value="Aktualisieren" />
      </form>
    </div>
    <div v-else-if="isBuyer">
      Sie sind Einkäufer
    </div>
    <div v-else>
      Hier könnten Ihre Account-Daten stehen! :)
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      username: "",
      pos: ""
    };
  },
  computed: {
    ...mapGetters(["isAdmin", "isBuyer"])
  }
};
</script>

<style scoped></style>
