import Vue from "vue";
import Router from "vue-router";
import store from "./store/index";
import Products from "./components/Products";
import Cart from "./components/Cart";
import StoredCarts from "./components/StoredCarts";
import SigninPage from "./components/auth/Signin";
import ExternalLogin from "./components/auth/ExternalLogin";
import AccountPage from "./components/Account";
import OrderList from "./components/OrderList";
import OrderDetails from "./components/OrderDetails";

Vue.use(Router);

const router = new Router({
  routes: [{
      path: "/",
      name: "home",
      component: Products,
      meta: {
        title: 'DRIVER C-Teile Shop'
      },
      beforeEnter(to, from, next) {
        if (store.state.token) {
          next();
        } else {
          next("/login");
        }
      }
    },
    {
      path: "/login",
      name: "login",
      component: SigninPage,
      meta: {
        title: 'DRIVER C-Teile Shop'
      }
    },
    {
      path: "/externallogin/:token/:basketname",
      name: "externallogin",
      component: ExternalLogin
    },
    {
      path: "/account",
      name: "account",
      component: AccountPage,
      beforeEnter(to, from, next) {
        if (store.state.token) {
          next();
        } else {
          next("/login");
        }
      }
    },
    //    {
    //      path: "/about",
    //      name: "about",
    //      // route level code-splitting
    //      // this generates a separate chunk (about.[hash].js) for this route
    //      // which is lazy-loaded when the route is visited.
    //      component: () =>
    //        import(/* webpackChunkName: "about" */ "./views/About.vue")
    //    },
    {
      path: "/cart",
      name: "cart",
      component: Cart,
      meta: {
        title: 'DRIVER C-Teile Shop | Warenkorb'
      },
      beforeEnter(to, from, next) {
        if (store.state.token) {
          next();
        } else {
          next("/login");
        }
      }
    },
    {
      path: "/storedcarts",
      name: "storedcarts",
      component: StoredCarts,
      meta: {
        title: 'DRIVER C-Teile Shop | Gespeicherte Warenkörbe'
      },
      beforeEnter(to, from, next) {
        if (store.state.token) {
          next();
        } else {
          next("/login");
        }
      }
    },
    {
      path: "/products",
      name: "products",
      component: Products,
      meta: {
        title: 'DRIVER C-Teile Shop | Produkte'
      },
      beforeEnter(to, from, next) {
        if (store.state.token) {
          next();
        } else {
          next("/login");
        }
      }
    },
    {
      path: "/products/search/:search",
      name: "products-search",
      component: Products,
      meta: {
        title: 'DRIVER C-Teile Shop | Suche'
      },
      beforeEnter(to, from, next) {
        if (store.state.token) {
          next();
        } else {
          next("/login");
        }
      }
    },
    {
      path: "/products/:category",
      name: "products-category",
      component: Products,
      meta: {
        title: 'DRIVER C-Teile Shop | Produkte'
      },
      beforeEnter(to, from, next) {
        if (store.state.token) {
          next();
        } else {
          next("/login");
        }
      }
    },
    {
      path: "/products/:category/:subcategory",
      name: "products-subcategory",
      component: Products,
      meta: {
        title: 'DRIVER C-Teile Shop | Produkte'
      },
      beforeEnter(to, from, next) {
        if (store.state.token) {
          next();
        } else {
          next("/login");
        }
      }
    },
    {
      path: "/orders",
      name: "order-list",
      component: OrderList,
      meta: {
        title: 'DRIVER C-Teile Shop | Bestellübersicht'
      },
      beforeEnter(to, from, next) {
        if (store.state.token) {
          next();
        } else {
          next("/login");
        }
      }
    },
    {
      path: "/orders/:orderid",
      name: "order-details",
      component: OrderDetails,
      meta: {
        title: 'DRIVER C-Teile Shop | Bestelldetails'
      },
      beforeEnter(to, from, next) {
        if (store.state.token) {
          next();
        } else {
          next("/login");
        }
      }
    }
  ]
});

let isFirstTransition = true;

router.beforeEach((to, from, next) => {
  const lastRouteName = localStorage.getItem("last_saved_route");

  const shouldRedirect = Boolean(
    to.name === "home" && lastRouteName && isFirstTransition
  );

  if (shouldRedirect) next({
    name: lastRouteName
  });
  else next();

  isFirstTransition = false;
});

router.afterEach(to => {
  localStorage.setItem("last_saved_route", to.name);
});

export default router;