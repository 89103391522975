<template>
  <div>
    <!-- <h1 class="title">All Products</h1> -->
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <div v-for="(order, index) in orders" v-bind:key="index" class="orderlist">
      {{ order.description }} - {{ order.reference }}
    </div>
    <div v-if="!orders">
      Es sind keine Bestellungen vorhanden.
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      errors: []
    };
  },
  name: "app",
  computed: mapGetters(["orders"]),
  /*
  methods: mapActions(["loadOrders", "setOrders"]),
  // Fetches products when the component is created.
  created() {
    const baseurl = `${window.location.origin}${window.location.pathname}api`;
    var target = `/orders`;
    if (this.orders == []) {
      // load orders from API and store locally
      target = "/orders";
      axios
        .get(baseurl + target)
        .then(response => {
          // JSON responses are automatically parsed.
          let res = response.data;
          let orders = [];
          //this.products = res.data;
          for (var property in res) {
            if (res.hasOwnProperty(property)) {
              //carts.push([property, res[property]]);
              let order = new Object((property = res[property]));
              orders.push(order);
            }
          }
          //this.orders(orders);
        })
        .catch(e => {
          this.errors.push(e);
        });
    }
  }
  */
};
</script>
<style>
/* .orderlist {
  display: inline;
} */
.orderlist button {
  margin: 20px;
  width: 150px;
  height: 40px;
}
</style>
