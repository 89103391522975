var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[_c('span',{staticClass:"float-lg-right"},[_vm._v(_vm._s(_vm.length)+" Artikel")]),_c('nav',{attrs:{"aria-label":"breadcrumb"}},[_c('ol',{staticClass:"breadcrumb"},[_c('li',{staticClass:"breadcrumb-item"},[_c('router-link',{attrs:{"to":{ name: 'products' }}},[_vm._v("C-Teile")])],1),(_vm.$route.params.category)?_c('li',{staticClass:"breadcrumb-item"},[_c('router-link',{attrs:{"to":{
              name: 'products-category',
              params: { category: _vm.$route.params.category }
            }}},[_vm._v(" "+_vm._s(_vm.$route.params.category)+" ")])],1):_vm._e(),(_vm.$route.params.subcategory)?_c('li',{staticClass:"breadcrumb-item"},[_c('router-link',{attrs:{"to":{
              name: 'products-subcategory',
              params: { subcategory: _vm.$route.params.subcategory }
            }}},[_vm._v(" "+_vm._s(_vm.$route.params.subcategory)+" ")])],1):_vm._e(),(_vm.$route.params.search)?_c('li',{staticClass:"breadcrumb-item"},[_vm._v(" Suche: "+_vm._s(_vm.$route.params.search)+" ")]):_vm._e()])])]),_c('table',{staticClass:"table is-striped"},[_c('thead',[_c('tr',[_c('th'),_c('th',[_vm._v("Beschreibung")]),_c('th',[_vm._v("Info")]),_c('th',[_vm._v("VE")]),_vm._m(0),(_vm.isBuyer)?_c('th',[_vm._v(" Lieferant ")]):_vm._e(),_c('th',[_vm._v("Menge")]),_c('th',[_vm._v("Lieferant")])])]),_c('tbody',_vm._l((_vm.products),function(product){return _c('tr',{key:product.genericItemId},[_c('td',[(product.supplierImage)?_c('img',{staticClass:"img_thumb",attrs:{"src":product.supplierImage,"alt":product.supplierDescription}}):(product.genericImage)?_c('img',{staticClass:"img_thumb",attrs:{"src":product.genericImage,"alt":product.supplierDescription}}):_c('img',{staticClass:"placeholder",attrs:{"src":"/img/placeholder.png"}})]),_c('td',[_vm._v(_vm._s(product.supplierDescription))]),_c('td',[(product.comment)?_c('button',{staticClass:"btn btn-secondary",attrs:{"type":"button","data-bs-toggle":"tooltip","data-bs-placement":"right","title":product.comment}},[_vm._v(" info ")]):_vm._e()]),_c('td',{staticClass:"right"},[_vm._v(" "+_vm._s(_vm._f("formatNumber")(product.packagesize))+" "+_vm._s(product.baseunit)+" ")]),_c('td',{staticStyle:{"text-align":"right"}},[_vm._v(" "+_vm._s(_vm._f("toCurrency")((product.price * product.packagesize)))+" "),_c('br'),(
              product.packagesize > 1 &&
                (product.baseunit == 'L' || product.baseunit == 'ST')
            )?_c('span',{staticStyle:{"font-size":"10px"}},[_vm._v(" ( "+_vm._s(_vm._f("toCurrency")((product.price * 1)))+"  pro  "+_vm._s(product.baseunit)+") ")]):_vm._e()]),(_vm.isBuyer)?_c('td',[_vm._v(" "+_vm._s(product.supplier_name)+" ")]):_vm._e(),_c('td',[_c('button',{staticClass:"button is-info",on:{"click":function($event){return _vm.addToCart(product)}}},[_vm._v(" + "+_vm._s(product.packagesize * 1)+" ")])]),_c('td',[_c('img',{attrs:{"src":("/img/logos/" + (product.supplierId) + ".png"),"height":"25px","alt":product.supplierName}})])])}),0)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('th',[_vm._v(" Preis pro VE"),_c('br'),_c('span',{staticStyle:{"font-size":"10px"}},[_vm._v("(Preis pro L / ST)")])])}]

export { render, staticRenderFns }