<template>
  <div id="notification_wrapper" v-show="notificationList.length">
    <div
      v-for="n in notificationList"
      v-bind:key="n.id"
      class="alert alert-dismissible"
      :class="[n.id > 100 ? 'alert-danger' : 'alert-success']"
    >
      <button
        type="button"
        class="close"
        data-dismiss="alert"
        @click="clearNotification(n.id)"
      >
        &times;
      </button>
      <span style="font-weight: bold;">{{ n.topic }}:</span> {{ n.message }}
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["notificationList"])
  },
  methods: {
    clearNotification(notificationId) {
      this.$store.dispatch("clearNotification", notificationId);
    }
  }
};
</script>
