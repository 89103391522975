<template>
  <div>
    <!-- <h1 class="title">All Products</h1> -->
    <div>
      <span class="float-lg-right">{{ length }} Artikel</span>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'products' }">C-Teile</router-link>
          </li>
          <li class="breadcrumb-item" v-if="$route.params.category">
            <router-link
              :to="{
                name: 'products-category',
                params: { category: $route.params.category }
              }"
            >
              {{ $route.params.category }}
            </router-link>
          </li>
          <li class="breadcrumb-item" v-if="$route.params.subcategory">
            <router-link
              :to="{
                name: 'products-subcategory',
                params: { subcategory: $route.params.subcategory }
              }"
            >
              {{ $route.params.subcategory }}
            </router-link>
          </li>
          <li class="breadcrumb-item" v-if="$route.params.search">
            Suche: {{ $route.params.search }}
          </li>
        </ol>
      </nav>
    </div>
    <!-- <button @click="filterProducts(new Object({'category':'Reifen','subcategory':'Ventile'}));">Ventile</button> -->
    <table class="table is-striped">
      <thead>
        <tr>
          <th></th>
          <th>Beschreibung</th>
          <th>Info</th>
          <th>VE</th>
          <!-- <th>VPE</th> -->
          <th>
            Preis pro VE<br />
            <span style="font-size: 10px;"
              >(Preis&nbsp;pro&nbsp;L&nbsp;/&nbsp;ST)</span
            >
          </th>
          <th v-if="isBuyer">
            Lieferant
          </th>
          <th>Menge</th>
          <th>Lieferant</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="product in products" v-bind:key="product.genericItemId">
          <td>
            <img
              v-if="product.supplierImage"
              :src="product.supplierImage"
              :alt="product.supplierDescription"
              class="img_thumb"
            />
            <img
              v-else-if="product.genericImage"
              :src="product.genericImage"
              :alt="product.supplierDescription"
              class="img_thumb"
            /> 
            <!-- <img v-else src="https://picsum.photos/120/60" class="img_thumb" /> -->
            <img v-else src="/img/placeholder.png" class="placeholder" />
          </td>
          <td>{{ product.supplierDescription }}</td>
          <td>
            <button
              v-if="product.comment"
              type="button"
              class="btn btn-secondary"
              data-bs-toggle="tooltip"
              data-bs-placement="right"
              :title="product.comment"
            >
              info
            </button>
          </td>
          <td class="right">
            {{ product.packagesize | formatNumber }}&nbsp;{{ product.baseunit }}
          </td>
          <!-- <td>{{ product.packageunit }}</td> -->
          <td style="text-align: right;">
            {{ (product.price * product.packagesize) | toCurrency }}
            <br />
            <span
              style="font-size: 10px;"
              v-if="
                product.packagesize > 1 &&
                  (product.baseunit == 'L' || product.baseunit == 'ST')
              "
            >
              (
              {{ (product.price * 1) | toCurrency }}
              &nbsp;pro&nbsp;
              {{ product.baseunit }})
            </span>
          </td>
          <td v-if="isBuyer">
            {{ product.supplier_name }}
          </td>
          <td>
            <button @click="addToCart(product)" class="button is-info">
              + {{ product.packagesize * 1 }}
            </button>
          </td>
          <td><img :src="`/img/logos/${product.supplierId}.png`" height="25px" :alt="product.supplierName" /></td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
//import axios from "axios";
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      errors: []
    };
  },
  name: "app",
  computed: mapGetters({
    products: "filteredProducts",
    length: "filteredProductsCount",
    totalLength: "allProductsCount",
    storedCarts: "storedCarts",
    isBuyer: "isBuyer"
  }),
  watch: {
    // $route(to, from) {
    $route() {
      let filter = new Object();
      if (this.$route.params.category) {
        filter.category = this.$route.params.category;
      }
      if (this.$route.params.subcategory) {
        filter.subcategory = this.$route.params.subcategory;
      }
      if (this.$route.params.search) {
        filter.search = this.$route.params.search;
      }
      //alert("Watch Filter: " + JSON.stringify(filter));
      this.filterProducts(filter);
    }
  },
  methods: mapActions([
    "addToCart",
    "removeFromCart",
    // "setAllProducts",
    "filterProducts",
    // "setStoredCarts"
    "loadProducts"
  ]),
  // Fetches products when the component is created.
  created() {
    //this.$store.dispatch("loadProducts");
    this.loadProducts();

    let filter = new Object();
    if (this.$route.params.category) {
      filter.category = this.$route.params.category;
    }
    if (this.$route.params.subcategory) {
      filter.subcategory = this.$route.params.subcategory;
    }
    if (this.$route.params.search) {
      filter.search = this.$route.params.search;
    }
    //alert("Watch Filter: " + JSON.stringify(filter));
    this.filterProducts(filter);
    // }
  }
};
</script>
<style>
.table td:first-child {
  padding: 0.5rem 1.5rem;
}
.right {
  text-align: right;
}
.img_thumb,
.placeholder {
  max-width: 120px;
  max-height: 90px;
  transition: transform 0.2s; /* Animation */
}
.img_thumb:hover {
  /* position: relative;
  top: 175px;
  left: 235px;
  width: auto;
  height: auto;
  display: block; */
  max-width: 100%;
  max-height: 100%;
  z-index: 999;
  transform: scale(1);
}
</style>
