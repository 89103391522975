<template>
  <div id="app" class="container">
    <nav-bar></nav-bar>
    <notification-bar></notification-bar>
    <router-view />
    <footer-bar></footer-bar>
  </div>
</template>

<script>
import NavBar from "./components/NavBar.vue";
import FooterBar from "./components/FooterBar.vue";
import NotificationBar from "./components/NotificationBar.vue";
import { mapActions } from "vuex";

export default {
  name: "app",
  components: {
    NavBar,
    FooterBar,
    NotificationBar
  },
  methods: {
    ...mapActions(["loginWithToken"])
  },
  data() {
    return {};
  },
  created() {
    if (this.$route.params.token) {
      let data = {
        token: this.$route.params.token,
        cartRef: this.$route.params.basketname
      };
      this.loginWithToken(data);
    } else {
      this.$store.dispatch("tryAutoLogin");
    }
    this.$router.push("/");
  }
};
</script>

<style>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  /* color: #2c3e50; */
  margin-top: 115px;
}
.navbar {
  border-bottom: 5px solid #ffdd00;
  border-top: 2px solid #ffdd00;
}
.bg-primary {
  background-color: #000 !important;
}
.btn-cta {
  background-color: #ffdd00 !important;
  color: #000 !important;
}
.table {
  text-align: left;
}
</style>
