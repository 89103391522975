import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import * as types from "./mutation-types";
import router from "../router";

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";

// mutations
const mutations = {
  [types.AUTH_USER](state, userData) {
    state.token = userData.token;
    state.userName = userData.userName;
    state.userRoles = userData.userRoles;
  },
  [types.STORE_USER](state, user) {
    state.user = user;
  },
  [types.SET_CART_REFERENCE](state, cartRef) {
    state.cartReference = cartRef;
  },
  [types.CLEAR_AUTH_DATA](state) {
    state.token = null;
    state.userName = null;
    state.user = null;
    state.added = [];
    state.filtered = [];
    state.storedCarts = [];
    state.cartReference = "";
    state.menuTree = {};
    state.all = [];
  },
  [types.ADD_TO_CART](state, {
    genericItemId,
    supplierItemId,
    supplierId,
    quantity = 1
  }) {
    const record = state.added.find(p => p.supplierItemId === supplierItemId);
    quantity = quantity * 1;

    if (!record) {
      state.added.push({
        genericItemId,
        supplierItemId,
        supplierId,
        quantity
      });
    } else {
      record.quantity = record.quantity * 1 + quantity;
    }
  },
  [types.REMOVE_FROM_CART](state, {
    supplierItemId
  }) {
    const record = state.added.find(p => p.supplierItemId === supplierItemId);

    if (record && record.quantity > 1) {
      record.quantity--;
    } else if (record) {
      var index = state.added.indexOf(record);
      if (index > -1) {
        state.added.splice(index, 1);
      }
    }
  },
  [types.SET_CART_QUANTITY](state, {
    genericItemId,
    supplierItemId,
    supplierId,
    quantity
  }) {
    const record = state.added.find(p => p.supplierItemId === supplierItemId);
    quantity = quantity * 1;

    if (!record) {
      state.added.push({
        genericItemId,
        supplierItemId,
        supplierId,
        quantity
      });
    } else {
      record.quantity = quantity;
    }
  },
  [types.DELETE_FROM_CART](state, {
    supplierItemId
  }) {
    const record = state.added.find(p => p.supplierItemId === supplierItemId);

    if (record) {
      var index = state.added.indexOf(record);
      if (index > -1) {
        state.added.splice(index, 1);
      }
    }
  },
  [types.EMPTY_CART](state) {
    state.cartReference = "";
    state.added = [];
  },
  [types.CREATE_ORDER](state) {
    state.cartReference = "";
    state.added = [];
  },
  [types.SAVE_STORED_CART](state) {
    state.cartReference = "";
    state.added = [];
  },
  [types.SET_PRODUCTS](state, {
    products
  }) {
    state.filtered = products;
  },
  [types.FILTER_PRODUCTS](state, {
    filter
  }) {
    let filtered = state.all;
    if (filter.hasOwnProperty("category")) {
      filtered = filtered.filter(p => p.category == filter.category);
    }
    if (filter.hasOwnProperty("subcategory")) {
      filtered = filtered.filter(p => p.category == filter.category);
      filtered = filtered.filter(p => p.subcategory == filter.subcategory);
    }
    if (filter.hasOwnProperty("search")) {
      let search = filter.search.toLowerCase();
      filtered = filtered.filter(p => {
        let inComment = false;
        if (p.comment) {
          inComment = p.comment.toLowerCase().indexOf(search) !== -1;
        }
        let inDesc = p.supplierDescription.toLowerCase().indexOf(search) !== -1;
        return inComment || inDesc;
      });
    }
    state.filtered = filtered;
  },
  [types.SET_ALL_PRODUCTS](state, {
    products
  }) {
    state.all = products;
    // state.filtered = state.all;
  },
  [types.SET_MENU](state, {
    menuTree
  }) {
    state.menuTree = menuTree;
    // state.filtered = state.all;
  },
  [types.SET_STORED_CARTS](state, {
    carts
  }) {
    if (Array.isArray(carts)) {
      state.storedCarts = carts;
    } else {
      state.storedCarts.push(carts);
    }
  },
  [types.SET_ORDERS](state, {
    orders
  }) {
    if (Array.isArray(orders)) {
      state.orders = orders;
    } else {
      state.orders.push(orders);
    }
  },
  [types.ADD_NOTIFICATION](state, {
    id,
    topic,
    message
  }) {
    state.notificationList.push({
      id,
      topic,
      message
    });
  },
  [types.CLEAR_NOTIFICATION](state, notificationId) {
    const record = state.notificationList.find(n => n.id === notificationId);

    if (record) {
      var index = state.notificationList.indexOf(record);
      if (index > -1) {
        state.notificationList.splice(index, 1);
      }
    }
  }
};

// initial state
const state = {
  token: null,
  userName: null,
  userRoles: null,
  user: null,
  added: [],
  filtered: [],
  storedCarts: [],
  orders: [],
  notificationList: [],
  // errorTexts: [
  //   {
  //     105: {
  //       de: {
  //         topic: "Problem bei der Anmeldung",
  //         message: "Ungültige Anmeldedaten."
  //       },
  //       en: {
  //         topic: "Authentication-Error",
  //         message: "Invalid credentials."
  //       }
  //     },
  //     106: {
  //       de: {
  //         topic: "Problem bei der Anmeldung",
  //         message: "Keine Anmeldedaten angegeben."
  //       },
  //       en: {
  //         topic: "Authentication-Error",
  //         message: "No credentials provided."
  //       }
  //     }
  //   }
  // ],
  cartReference: "",
  menuTree: {},
  all: [],
  errors: []
};

// let axios_api = axios.create({
//   headers: {
//     common: {
//       Authorization: `Bearer ${state.token}`
//     }
//   }
// });

// getters
const getters = {
  user: state => state.user,
  isAuthenticated: state => state.token !== null,
  isAdmin: state => state.userRoles === "admin",
  isBuyer: state => state.userRoles === "buyer",
  notificationList: state => state.notificationList,
  menuTree: state => state.menuTree,
  allProducts: state => state.all, // would need action/mutation if data fetchd async
  filteredProducts: state => state.filtered,
  filteredProductsCount: state => (state.filtered ? state.filtered.length : 0),
  allProductsCount: state => (state.all ? state.all.length : 0),
  cartProducts: state => {
    return state.added.map(({
      genericItemId,
      quantity
    }) => {
      const product = state.all.find(p => p.genericItemId === genericItemId);

      return {
        genericItemId: product.genericItemId,
        supplierItemId: product.supplierItemId,
        supplierId: product.supplierId,
        supplierDescription: product.supplierDescription,
        genericImage: product.genericImage,
        packagesize: product.packagesize,
        baseunit: product.baseunit,
        packageunit: product.packageunit,
        price: product.price,
        quantity: quantity * 1
      };
    });
  },
  itemsInCart: state => {
    let cart = state.added;
    return cart.reduce((accum, item) => accum + item.quantity, 0);
  },
  storedCarts: state => {
    return state.storedCarts.length > 0 ? state.storedCarts : false;
  },
  orders: state => {
    return state.orders.length > 0 ? state.orders : false;
  }
};

// actions
const actions = {
  setLogoutTimer({
    dispatch
  }, expirationTime) {
    setTimeout(() => {
      //commit(types.CLEAR_AUTH_DATA);
      dispatch("logout");
    }, expirationTime * 1000);
  },
  login({
    commit,
    dispatch
  }, authData) {
    const baseurl = "https://cparts.driver-center.de/api";
    let target = "/auth";
    axios
      .post(baseurl + target, {
        login: authData.login,
        password: authData.password
      })
      .then(res => {
        if (res.data.error_code > 0) {
          dispatch("addNotification", {
            id: res.data.error_code,
            topic: res.data.error_topic,
            message: res.data.error_text
          });
        } else {
          const now = new Date();
          const expDate = new Date(now.getTime() + res.data.expiresIn * 1000);
          localStorage.setItem("token", res.data.token);
          localStorage.setItem("userName", res.data.username);
          localStorage.setItem("userRoles", res.data.userRoles);
          localStorage.setItem("expirationDate", expDate);
          commit(types.AUTH_USER, {
            token: res.data.token,
            userName: res.data.username,
            userRoles: res.data.userRoles
          });
          dispatch("setLogoutTimer", res.data.expiresIn);
          dispatch("loadStoredCarts");
          dispatch("loadOrders");
          router.push("/");
        }
      })
      .catch(error => console.log("idx 340: " + error));
  },
  loginWithToken({
    commit,
    dispatch
  }, data) {
    const baseurl = "https://cparts.driver-center.de/api";
    let target = "/auth";
    axios
      .post(baseurl + target, {
        token: data.token
      })
      .then(res => {
        const now = new Date();
        const expDate = new Date(now.getTime() + res.data.expiresIn * 1000);
        localStorage.setItem("token", res.data.token);
        localStorage.setItem("userName", res.data.username);
        localStorage.setItem("userRoles", res.data.userRoles);
        localStorage.setItem("expirationDate", expDate);
        commit(types.AUTH_USER, {
          token: res.data.token,
          userName: res.data.username,
          userRoles: res.data.userRoles
        });
        commit(types.SET_CART_REFERENCE, data.cartRef);
        dispatch("setLogoutTimer", res.data.expiresIn);
        dispatch("loadStoredCarts");
        dispatch("loadOrders");
        router.push("/");
      })
      .catch(error => console.log("idx364: " + error));
  },
  tryAutoLogin({
    dispatch,
    commit
  }) {
    const token = localStorage.getItem("token");
    if (!token) {
      return;
    }
    const expirationDate = localStorage.getItem("expirationDate");
    const now = new Date();
    if (now >= expirationDate) {
      dispatch("logout");
      return;
    }
    const userName = localStorage.getItem("userName");
    commit(types.AUTH_USER, {
      token: token,
      userName: userName,
      userRoles: userRoles
    });
  },
  logout({
    commit
  }) {
    commit(types.CLEAR_AUTH_DATA);
    localStorage.removeItem("expirationDate");
    localStorage.removeItem("token");
    localStorage.removeItem("userName");
    localStorage.removeItem("userRoles");
    router.replace("/login");
  },
  loadProducts({
    dispatch
  }) {
    //const baseurl = `${window.location.origin}${window.location.pathname}api`;
    const baseurl = "https://cparts.driver-center.de/api";
    var target = `/items`;
    // if (this.getters.allProductsCount < 7) {
    axios
      .get(baseurl + target, {
        headers: {
          Authorization: `Bearer ${state.token}`
        }
      })
      .then(response => {
        // JSON responses are automatically parsed.
        let res = response.data;
        //this.products = res.data;
        dispatch("setAllProducts", res);
      })
      .catch(e => {
        this.errors.push(e);
      });
    // }
  },
  loadStoredCarts({
    state,
    dispatch
  }) {
    // load stored_carts from API and store locally
    //const baseurl = `${window.location.origin}${window.location.pathname}api`;
    const baseurl = "https://cparts.driver-center.de/api";
    let target = "/stored_carts";
    axios
      .get(baseurl + target, {
        headers: {
          Authorization: `Bearer ${state.token}`
        }
      })
      .then(response => {
        // JSON responses are automatically parsed.
        let res = response.data;
        let carts = [];
        //this.products = res.data;
        for (var property in res) {
          if (res.hasOwnProperty(property)) {
            //carts.push([property, res[property]]);
            let cart = new Object((property = res[property]));
            carts.push(cart);
          }
        }
        dispatch("setStoredCarts", carts);
      })
      .catch(e => {
        this.errors.push(e);
      });
  },
  loadOrders({
    state,
    dispatch
  }) {
    // load stored_carts from API and store locally
    //const baseurl = `${window.location.origin}${window.location.pathname}api`;
    const baseurl = "https://cparts.driver-center.de/api";
    let target = "/orders";
    axios
      .get(baseurl + target, {
        headers: {
          Authorization: `Bearer ${state.token}`
        }
      })
      .then(response => {
        // JSON responses are automatically parsed.
        let res = response.data;
        let orders = [];
        //this.products = res.data;
        for (var property in res) {
          if (res.hasOwnProperty(property)) {
            //carts.push([property, res[property]]);
            let order = new Object((property = res[property]));
            orders.push(order);
          }
        }
        dispatch("setOrders", orders);
      })
      .catch(e => {
        this.errors.push(e);
      });
  },
  addToCart({
    commit,
    state,
    dispatch
  }, product) {
    // retrieve the element
    let element = document.getElementById("cart");
    // -> removing the class
    element.classList.remove("pulsate-fwd");

    // -> triggering reflow /* The actual magic */
    // without this it wouldn't work. Try uncommenting the line and the transition won't be retriggered.
    // Oops! This won't work in strict mode. Thanks Felis Phasma!
    // element.offsetWidth = element.offsetWidth;
    // Do this instead:
    void element.offsetWidth;
    // -> and re-adding the class
    element.classList.add("pulsate-fwd");
    if (Array.isArray(product)) {
      product.map(p => {
        let curProduct = state.all.find(a => a.genericItemId === p.genericItemId);
        //console.log(p);
        if (curProduct.packagesize > p.amount) {
          p.amount = curProduct.packagesize;
        }
        let amountRest = p.amount * 1;
        let amountNew = 0;
        do {
          amountNew = amountNew + curProduct.packagesize * 1;
          amountRest = amountRest - curProduct.packagesize * 1;
        } while (amountRest > 0);

        //console.log(curProduct);
        commit(types.ADD_TO_CART, {
          genericItemId: p.genericItemId,
          supplierItemId: curProduct.supplierItemId,
          supplierId: curProduct.supplierId,
          quantity: amountNew
        });

        // Hinweis auf große Abweichungen bei geänderten Verpackungsgrößen
        if (amountRest < p.amount * -0.1) {
          dispatch("addNotification", {
            id: 84,
            topic: "Warenkorb-Vorlage",
            message: "Achtung! Die Bestellmenge hat sich geändert."
          });
        }
      });
    } else {
      commit(types.ADD_TO_CART, {
        genericItemId: product.genericItemId,
        supplierItemId: product.supplierItemId,
        supplierId: product.supplierId,
        quantity: product.packagesize
      });
    }
  },
  removeFromCart({
    commit
  }, product) {
    // retrieve the element
    let element = document.getElementById("cart");
    // -> removing the class
    element.classList.remove("pulsate-fwd");

    // -> triggering reflow /* The actual magic */
    // without this it wouldn't work. Try uncommenting the line and the transition won't be retriggered.
    // Oops! This won't work in strict mode. Thanks Felis Phasma!
    // element.offsetWidth = element.offsetWidth;
    // Do this instead:
    void element.offsetWidth;
    // -> and re-adding the class
    element.classList.add("pulsate-fwd");
    commit(types.REMOVE_FROM_CART, {
      supplierItemId: product.supplierItemId
    });
  },
  setCartQuantity({
    commit
  }, newQuantity) {
    // retrieve the element
    let element = document.getElementById("cart");
    // -> removing the class
    element.classList.remove("pulsate-fwd");

    // -> triggering reflow /* The actual magic */
    // without this it wouldn't work. Try uncommenting the line and the transition won't be retriggered.
    // Oops! This won't work in strict mode. Thanks Felis Phasma!
    // element.offsetWidth = element.offsetWidth;
    // Do this instead:
    void element.offsetWidth;
    // -> and re-adding the class
    element.classList.add("pulsate-fwd");
    commit(types.SET_CART_QUANTITY, {
      genericItemId: newQuantity.product.genericItemId,
      supplierItemId: newQuantity.product.supplierItemId,
      supplierId: newQuantity.product.supplierId,
      quantity: newQuantity.quantity
    });
  },
  deleteFromCart({
    commit
  }, product) {
    // retrieve the element
    let element = document.getElementById("cart");
    // -> removing the class
    element.classList.remove("pulsate-fwd");

    // -> triggering reflow /* The actual magic */
    // without this it wouldn't work. Try uncommenting the line and the transition won't be retriggered.
    // Oops! This won't work in strict mode. Thanks Felis Phasma!
    // element.offsetWidth = element.offsetWidth;
    // Do this instead:
    void element.offsetWidth;
    // -> and re-adding the class
    element.classList.add("pulsate-fwd");
    commit(types.DELETE_FROM_CART, {
      supplierItemId: product.supplierItemId
    });
  },
  emptyCart({
    commit
  }) {
    // retrieve the element
    let element = document.getElementById("cart");
    // -> removing the class
    element.classList.remove("pulsate-fwd");

    // -> triggering reflow /* The actual magic */
    // without this it wouldn't work. Try uncommenting the line and the transition won't be retriggered.
    // Oops! This won't work in strict mode. Thanks Felis Phasma!
    // element.offsetWidth = element.offsetWidth;
    // Do this instead:
    void element.offsetWidth;
    // -> and re-adding the class
    element.classList.add("pulsate-fwd");
    commit(types.EMPTY_CART, {});
  },
  addNotification({
    commit,
    dispatch
  }, {
    id,
    topic,
    message
  }) {
    commit(types.ADD_NOTIFICATION, {
      id,
      topic,
      message
    });
    setTimeout(() => {
      dispatch("clearNotification", id);
    }, 5 * 1000);
  },
  clearNotification({
    commit
  }, notificationId) {
    commit(types.CLEAR_NOTIFICATION, notificationId);
  },
  createOrder({
    commit,
    state,
    dispatch
  }, cartName) {
    if (!state.token) {
      return;
    }
    //const baseurl = `${window.location.origin}${window.location.pathname}api`;
    const baseurl = "https://cparts.driver-center.de/api";
    let target = `/order/create`;

    axios
      .post(
        baseurl + target, {
          description: cartName,
          reference: state.cartReference,
          positions: state.added
        }, {
          headers: {
            Authorization: `Bearer ${state.token}`
          }
        }
      )
      .then(response => {
        // JSON responses are automatically parsed.
        if (response.status == 200) {
          commit(types.CREATE_ORDER, {});
          dispatch("addNotification", {
            id: 53,
            topic: "Warenkorb",
            message: "Ihr Warenkorb wurde gespeichert. Bitte Warenkorb im SAP abholen!"
          });
        } else {
          dispatch("addNotification", {
            id: 153,
            topic: "Warenkorb",
            message: "Beim Speichern ist ein Fehler aufgetreten."
          });
        }
      })
      .catch(e => {
        this.errors.push(e);
      });
  },
  createStoredCart({
    commit,
    state,
    dispatch
  }, cartName) {
    if (!state.token) {
      return;
    }
    //const baseurl = `${window.location.origin}${window.location.pathname}api`;
    const baseurl = "https://cparts.driver-center.de/api";
    let target = `/stored_carts/create`;

    axios
      .post(
        baseurl + target, {
          description: cartName,
          positions: state.added
        }, {
          headers: {
            Authorization: `Bearer ${state.token}`
          }
        }
      )
      .then(response => {
        // JSON responses are automatically parsed.
        if (response.status == 200) {
          commit(types.SAVE_STORED_CART, {});
          dispatch("loadStoredCarts");
          dispatch("loadOrders");
          dispatch("addNotification", {
            id: 73,
            topic: "Warenkorb-Vorlage",
            message: "Ihre Vorlage wurde gespeichert."
          });
        } else {
          dispatch("addNotification", {
            id: 173,
            topic: "Warenkorb-Vorlage",
            message: "Ihre Vorlage konnte nicht gespeichert werden."
          });
        }
      })
      .catch(e => {
        dispatch("addNotification", {
          id: 199,
          topic: "Fehler",
          message: JSON.stringify(e)
        });
        // this.errors.push(e);
      });
  },
  deleteStoredCart({
    state,
    dispatch
  }, cartName) {
    if (!state.token) {
      return;
    }
    //const baseurl = `${window.location.origin}${window.location.pathname}api`;
    const baseurl = "https://cparts.driver-center.de/api";
    let target = `/stored_carts/${cartName}`;

    axios
      .delete(baseurl + target, {
        headers: {
          Authorization: `Bearer ${state.token}`
        }
      })
      .then(response => {
        // JSON responses are automatically parsed.
        if (response.status == 200) {
          dispatch("loadStoredCarts");
          dispatch("loadOrders");
          dispatch("addNotification", {
            id: 74,
            topic: "Warenkorb-Vorlage",
            message: "Ihre Vorlage wurde gelöscht."
          });
        } else {
          dispatch("addNotification", {
            id: 174,
            topic: "Warenkorb-Vorlage",
            message: "Beim Löschen der Vorlage ist ein Fehler aufgetreten."
          });
        }
      })
      .catch(e => {
        dispatch("addNotification", {
          id: 199,
          topic: "Fehler",
          message: JSON.stringify(e)
        });
        // this.errors.push(e);
      });
  },
  getAllProducts({
    commit
  }, products) {
    commit(types.SET_PRODUCTS, {
      products: products
    });
  },
  setProducts({
    commit
  }, products) {
    commit(types.SET_PRODUCTS, {
      products: products
    });
  },
  setAllProducts({
    commit
  }, products) {
    commit(types.SET_ALL_PRODUCTS, {
      products: products
    });
    let categories = products.map(product => product.category);
    let uniqueCategories = [...new Set(categories)];
    var menuTree = {};

    for (var i in uniqueCategories) {
      let subcategories = products
        .filter(product => product.category == uniqueCategories[i])
        .map(product => product.subcategory);
      let uniqueSubcategories = [...new Set(subcategories)];
      menuTree[uniqueCategories[i]] = uniqueSubcategories;
    }
    commit(types.SET_MENU, {
      menuTree: menuTree
    });
  },
  filterProducts({
    commit
  }, filter) {
    commit(types.FILTER_PRODUCTS, {
      filter: filter
    });
  },
  setStoredCarts({
    commit
  }, carts) {
    commit(types.SET_STORED_CARTS, {
      carts: carts
    });
  },
  setOrders({
    commit
  }, orders) {
    commit(types.SET_ORDERS, {
      orders: orders
    });
  }
};

// one store for entire application
export default new Vuex.Store({
  state,
  strict: debug,
  getters,
  actions,
  mutations
});