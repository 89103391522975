// Authentication and Authorization
export const AUTH_USER = "AUTH_USER";
export const STORE_USER = "STORE_USER";
export const CLEAR_AUTH_DATA = "CLEAR_AUTH_DATA";
export const CLEAR_NOTIFICATION = "CLEAR_NOTIFICATION";
export const ADD_NOTIFICATION = "ADD_NOTIFICATION";

// Cart
export const ADD_TO_CART = "ADD_TO_CART";
export const REMOVE_FROM_CART = "REMOVE_FROM_CART";
export const SET_CART_QUANTITY = "SET_CART_QUANTITY";
export const DELETE_FROM_CART = "DELETE_FROM_CART";
export const EMPTY_CART = "EMPTY_CART";
export const SET_STORED_CARTS = "SET_STORED_CARTS";
export const SET_ORDERS = "SET_ORDERS";
export const SAVE_STORED_CART = "SAVE_STORED_CART";
export const CREATE_ORDER = "CREATE_ORDER";
export const SET_CART_REFERENCE = "SET_CART_REFERENCE";
export const SET_PRODUCTS = "SET_PRODUCTS";
export const FILTER_PRODUCTS = "FILTER_PRODUCTS";
export const SET_MENU = "SET_MENU";
export const SET_ALL_PRODUCTS = "SET_ALL_PRODUCTS";
export const GET_ALL_PRODUCTS = "GET_ALL_PRODUCTS";
export const GET_PRODUCTS_BY_CATEGORY = "GET_PRODUCTS_BY_CATEGORY";
export const GET_PRODUCTS_BY_SUBCATEGORY = "GET_PRODUCTS_BY_SUBCATEGORY";
