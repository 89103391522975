<template>
  <div class="row">
    <div class="container">
      <h1>Warenkorb</h1>
      <p v-show="!cartProducts.length">
        <i>Ihr Warenkorb ist leer!</i>
        <br /><br />
        <router-link :to="{ name: 'products' }">
          zurück zu den Artikeln
        </router-link>
      </p>
      <table class="table" v-show="cartProducts.length">
        <thead>
          <tr>
            <td>Bild</td>
            <td>Beschreibung</td>
            <td>Inhalt pro VPE</td>
            <!-- <td>VPE</td> -->
            <td>Einzelpreis</td>
            <td>Menge</td>
            <td>Summe</td>
            <td>Lieferant</td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="product in cartProducts" v-bind:key="product.id">
            <td>
              <img
                v-if="product.supplierImage"
                :src="product.supplierImage"
                :alt="product.supplierDescription"
                class="img_thumb"
              />
              <img
                v-else-if="product.genericImage"
                :src="product.genericImage"
                :alt="product.supplierDescription"
                class="img_thumb"
              />
              <img v-else src="/img/placeholder.png" class="placeholder" />
            </td>
            <td>{{ product.supplierDescription }}</td>
            <td>
              {{ product.packagesize | formatNumber }} {{ product.baseunit }}
            </td>
            <!-- <td>{{ product.packageunit }}</td> -->
            <td style="text-align: right;">
              {{ (product.price * 1) | toCurrency }}
            </td>
            <td>
              <!-- <button @click="removeFromCart(product)" class="button is-info">
                -
              </button>
              &nbsp;{{ product.quantity }}&nbsp;
              <button @click="addToCart(product)" class="button is-info">
                +
              </button> -->
              <input
                type="number"
                :min="product.packagesize"
                :step="product.packagesize"
                :value="product.quantity"
                v-on:input="
                  setCartQuantity({
                    product: product,
                    quantity: $event.target.value
                  })
                "
              />
              &nbsp;
              <button @click="deleteFromCart(product)" class="button">
                <font-awesome-icon icon="trash-alt" size="lg" />
              </button>
            </td>
            <td>
              {{ (product.price * product.quantity) | toCurrency }}
            </td>
            <td>
              <img :src="`/img/logos/${product.supplierId}.png`" height="25px" :alt="product.supplierName" />
            </td>
          </tr>
          <tr>
            <td><b>Gesamt:</b></td>
            <td></td>
            <td>
              <b>{{ total | toCurrency }}</b>
            </td>
          </tr>
        </tbody>
      </table>
      <div v-show="cartProducts.length">
        <p>
          <label for="cartName">Warenkorb-Name:</label>
          <input type="text" name="cartName" id="cartName" v-model="cartName" />
        </p>
        <button
          :disabled="!cartName"
          class="btn btn-secondary"
          @click="
            createStoredCart(cartName);
            cartName = '';
          "
        >
          Als Vorlage speichern
        </button>
        &nbsp;-&nbsp;
        <button
          class="btn btn-primary"
          @click="
            createOrder(cartName);
            cartName = '';
          "
        >
          Warenkorb übergeben
        </button>
        &nbsp;-&nbsp;
        <button class="btn btn-secondary" @click="emptyCart()">
          Warenkorb leeren
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      cartName: ""
    };
  },
  computed: {
    ...mapGetters(["cartProducts", "itemsInCart"]),
    total() {
      return this.cartProducts.reduce((total, p) => {
        return total + p.price * p.quantity;
      }, 0);
    }
  },
  methods: {
    ...mapActions([
      "addToCart",
      "removeFromCart",
      "setCartQuantity",
      "deleteFromCart",
      "emptyCart",
      "createOrder",
      "createStoredCart"
    ])
  }
};
</script>

<style>
.img_thumb {
  max-width: 120px;
}
input[type="number"] {
  width: 50px;
}
</style>
