<template>
  <div>
    <!-- <h1 class="title">All Products</h1> -->
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />

    <div
      v-for="(cart, index) in storedCarts"
      v-bind:key="index"
      class="cartlist"
    >
      <button
        @click="
          addToCart(storedCarts[index].positions);
          $router.push({ name: 'cart' });
        "
      >
        {{ cart.description }}
      </button>
      <button @click="deleteStoredCart(cart.description)" class="button">
        <font-awesome-icon icon="trash-alt" size="lg" />
      </button>
      <!-- <br /> -->
      <!-- <button @click="this.$router.push({ name: 'cart' });">{{ cart.description }}</button> -->
    </div>
    <div v-if="!storedCarts">
      Es sind keine Vorlagen vorhanden.
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      errors: []
    };
  },
  name: "app",
  computed: mapGetters(["storedCarts"]),
  methods: mapActions(["addToCart", "setStoredCarts", "deleteStoredCart"]),
  // Fetches products when the component is created.
  created() {
    const baseurl = `${window.location.origin}${window.location.pathname}api`;
    var target = `/stored_carts`;
    if (this.storedCarts == []) {
      // load stored_carts from API and store locally
      target = "/stored_carts";
      axios
        .get(baseurl + target)
        .then(response => {
          // JSON responses are automatically parsed.
          let res = response.data;
          let carts = [];
          //this.products = res.data;
          for (var property in res) {
            if (res.hasOwnProperty(property)) {
              //carts.push([property, res[property]]);
              let cart = new Object((property = res[property]));
              carts.push(cart);
            }
          }
          this.setStoredCarts(carts);
        })
        .catch(e => {
          this.errors.push(e);
        });
    }
  }
};
</script>
<style>
/* .cartlist {
  display: inline;
} */
.cartlist button {
  margin: 20px;
  width: 150px;
  height: 40px;
}
</style>
