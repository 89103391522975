import "./assets/bootstrap.4.3.1.min.css";
import "./assets/custom.css";
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store/index.js";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faSearch } from "@fortawesome/free-solid-svg-icons/faSearch";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons/faTrashAlt";
import { faShoppingCart } from "@fortawesome/free-solid-svg-icons/faShoppingCart";
import { faHistory } from "@fortawesome/free-solid-svg-icons/faHistory";
import { faUser } from "@fortawesome/free-solid-svg-icons/faUser";
import { faQuestion } from "@fortawesome/free-solid-svg-icons/faQuestion";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(
  faSearch,
  faTrashAlt,
  faShoppingCart,
  faHistory,
  faUser,
  faQuestion
);

Vue.component("font-awesome-icon", FontAwesomeIcon);

Vue.filter("toCurrency", function(value) {
  if (typeof value !== "number") {
    return value;
  }
  var formatter = new Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: "EUR",
    minimumFractionDigits: 2
  });
  return formatter.format(value);
});

Vue.filter("formatNumber", function(value) {
  if (!value) return "";
  value = value.toString();
  return value.replace(/0+$/, "").replace(/\.$/, "");
});

Vue.config.productionTip = false;

// element-closest Polyfill:
!(function(e) {
  var t = e.Element.prototype;
  "function" != typeof t.matches &&
    (t.matches =
      t.msMatchesSelector ||
      t.mozMatchesSelector ||
      t.webkitMatchesSelector ||
      function(e) {
        for (
          var t = (this.document || this.ownerDocument).querySelectorAll(e),
            o = 0;
          t[o] && t[o] !== this;

        )
          ++o;
        return Boolean(t[o]);
      }),
    "function" != typeof t.closest &&
      (t.closest = function(e) {
        for (var t = this; t && 1 === t.nodeType; ) {
          if (t.matches(e)) return t;
          t = t.parentNode;
        }
        return null;
      });
})(window);

// Define route

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
