<template>
  <div>
    <!-- <h1 class="title">All Products</h1> -->
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      errors: []
    };
  },
  name: "app",
  methods: mapActions(["loginWithToken"]),
  // Fetches products when the component is created.
  created() {
    if (this.$route.params.token) {
      this.loginWithToken(
        this.$route.params.token,
        this.$route.params.basketname
      );
    } else {
      router.push("/login");
      dispatch("addNotification", {
        id: 401,
        topic: `Not Authorized!`,
        message: `Kein Authorisierungstoken vorhanden!`
      });
    }
  }
};
</script>

<style></style>
