<template>
  <div id="signin">
    <div class="signin-form">
      <form @submit.prevent="onSubmit">
        <div class="input">
          <label for="login">Benutzer</label>
          <input type="text" id="login" v-model="login" />
        </div>
        <div class="input">
          <label for="password">Passwort</label>
          <input type="password" id="password" v-model="password" />
        </div>
        <div class="submit">
          <button type="submit">Anmelden</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      login: "",
      password: ""
    };
  },
  methods: {
    onSubmit() {
      const formData = {
        login: this.login,
        password: this.password
      };
      this.$store.dispatch("login", {
        login: formData.login,
        password: formData.password
      });
    }
  }
};
</script>

<style scoped>
.signin-form {
  width: 400px;
  margin: 160px auto;
  border: 1px solid #eee;
  padding: 20px;
  box-shadow: 0 2px 3px #ccc;
}

.input {
  margin: 10px auto;
}

.input label {
  display: block;
  color: #4e4e4e;
  margin-bottom: 6px;
}

.input input {
  font: inherit;
  width: 100%;
  padding: 6px 12px;
  box-sizing: border-box;
  border: 1px solid #ccc;
}

.input input:focus {
  outline: none;
  border: 1px solid #ffdd00;
  background-color: #eee;
}

.submit button {
  border: 1px solid #ffdd00;
  color: #000;
  padding: 10px 20px;
  font: inherit;
  cursor: pointer;
}

.submit button:hover,
.submit button:active {
  background-color: #ffdd00;
  color: #000;
}

.submit button[disabled],
.submit button[disabled]:hover,
.submit button[disabled]:active {
  border: 1px solid #ccc;
  background-color: transparent;
  color: #ccc;
  cursor: not-allowed;
}
</style>
