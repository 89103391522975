<template>
  <div>
    <!-- <h1 class="title">All Products</h1> -->
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <table>
      <tr>
        <th></th>
        <th></th>
        <th></th>
        <th></th>
      </tr>
      <tr v-for="(position, index) in order" v-bind:key="index">
        <td></td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
    </table>

    <div v-if="!orders">
      Es sind keine Bestellungen vorhanden.
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      errors: []
    };
  },
  name: "app",
  computed: mapGetters(["Orders"])
};
</script>

<style></style>
