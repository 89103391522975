<template>
  <div
    id="nav"
    class="navbar navbar-expand-lg fixed-top navbar-dark bg-primary"
  >
    <div class="container" style="max-width:100%;">
      <router-link to="/" class="navbar-brand">
        <img src="../assets/Driver_Logo_DE_sw_min.png" alt="DRIVER" />
      </router-link>
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarResponsive"
        aria-controls="navbarResponsive"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon" @click="toggleNavbar()"></span>
      </button>
      <div
        class="collapse navbar-collapse"
        id="navbarResponsive"
        :class="[isCollapsed ? '' : 'show']"
      >
        <ul v-if="isAuthenticated" class="navbar-nav">
          <dropdown
            v-for="(value, propertyName) in menuTree"
            v-bind:key="propertyName"
            class="'nav-item dropdown t'"
          >
            <template slot="btn">
              {{ propertyName }}
            </template>
            <template slot="body">
              <router-link
                :to="{
                  name: 'products-category',
                  params: { category: propertyName }
                }"
                class="dropdown-item"
              >
                {{ propertyName }}
              </router-link>
              <div class="dropdown-divider"></div>
              <router-link
                v-for="subcat in value"
                v-bind:key="subcat"
                :to="{
                  name: 'products-subcategory',
                  params: {
                    category: propertyName,
                    subcategory: subcat
                  }
                }"
                class="dropdown-item"
              >
                {{ subcat }}
              </router-link>
            </template>
          </dropdown>
          <!-- <dropdown :class-name="'nav-item dropdown t'">
            <template slot="btn">
              Werkzeuge
            </template>
            <template slot="body">
              <router-link to="/products/Werkzeuge" class="dropdown-item">
                Werkzeuge
              </router-link>
            </template>
          </dropdown> -->
        </ul>
        <div v-if="isAuthenticated" class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text" id="search">
              <font-awesome-icon icon="search" size="lg" />
            </span>
          </div>
          <input
            type="text"
            v-model="search"
            class="form-control"
            placeholder="Suche"
            aria-label="search"
            aria-describedby="search"
            @keyup.enter="doSearch"
          />
        </div>

        <ul class="nav navbar-nav ml-auto nav-general">
          <dropdown v-if="isAuthenticated" :class-name="'nav-item dropdown t'">
            <template slot="btn">
              <font-awesome-icon icon="question" size="lg" />
            </template>
            <template slot="body">
              <a href="mailto:einkauf@drivercenter.de">
                Inhaltliche Fragen
              </a>
              <div class="dropdown-divider"></div>
              <a href="mailto:edv@drivercenter.de">Technische Probleme</a>
            </template>
          </dropdown>
          <li v-if="isAuthenticated" class="nav-item">
            <router-link to="/storedcarts" class="nav-link">
              <font-awesome-icon icon="history" size="lg" />
            </router-link>
          </li>
          <li v-if="isAuthenticated" class="nav-item">
            <router-link to="/cart" class="nav-link" id="cart">
              <!-- Warenkorb -->
              <font-awesome-icon icon="shopping-cart" size="lg" />
              {{ itemsInCart }}
            </router-link>
          </li>
          <li v-if="!isAuthenticated" class="nav-item">
            <router-link to="/login" class="nav-link" id="login-nav">
              <font-awesome-icon icon="user" size="lg" />
            </router-link>
          </li>
          <dropdown v-if="isAuthenticated" :class-name="'nav-item dropdown t'">
            <template slot="btn">
              <font-awesome-icon icon="user" size="lg" />
            </template>
            <template slot="body">
              <router-link to="/orders" class="dropdown-item">
                Bestellungen
              </router-link>
              <router-link to="/account" class="dropdown-item">
                Account
              </router-link>
              <div class="dropdown-divider"></div>
              <a @click="onLogout()">
                Logout
              </a>
            </template>
          </dropdown>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Dropdown from "./Dropdown.vue";

export default {
  data() {
    return {
      search: "",
      isCollapsed: true
    };
  },
  watch: {
    // $route(to, from) {
    $route() {
      if (!this.$route.params.search) {
        this.search = "";
      }
    }
  },
  components: { Dropdown },
  computed: {
    // itemsInCart() {
    //   let cart = this.$store.getters.cartProducts;
    //   return cart.reduce((accum, item) => accum + item.quantity, 0);
    // },
    // auth() {
    //   return this.$store.getters.isAuthenticated;
    // },
    // ...mapGetters({
    //   menuTree: "getMenuTree"
    // }),
    ...mapGetters(["menuTree", "itemsInCart", "isAuthenticated"])
  },
  methods: {
    doSearch: function() {
      this.$router.push({
        name: "products-search",
        params: { search: this.search }
      });
    },
    onLogout() {
      this.$store.dispatch("logout");
    },
    toggleNavbar: function() {
      this.isCollapsed = !this.isCollapsed;
    }
  }
};
</script>

<style>
.input-group-text,
.form-control {
  padding: 0.75rem;
}
.input-group {
  width: inherit;
}
#nav a.router-link-exact-active {
  cursor: pointer;
  color: #fff;
  font-weight: 700;
}
#nav a.router-link-exact-active.dropdown-item {
  cursor: pointer;
  color: #000;
  font-weight: 700;
}
.nav-item {
  margin-right: 0.5rem;
}
.nav-link {
  letter-spacing: 0;
  text-transform: none;
}
.nav-general {
  min-width: 160px;
}
/* ----------------------------------------------
 * Generated by Animista on 2019-6-3 10:18:50
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation pulsate-fwd
 * ----------------------------------------
 */
@-webkit-keyframes pulsate-fwd {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    color: #ffdd00;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes pulsate-fwd {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    color: #ffdd00;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
.pulsate-fwd {
  -webkit-animation: pulsate-fwd 0.4s ease-in-out both;
          animation: pulsate-fwd 0.4s ease-in-out both;
}
</style>
